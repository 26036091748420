.image-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .nav-button.left {
    left: 10px;
  }
  
  .nav-button.right {
    right: 10px;
  }
  
  .image-thumb-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }
  
  .thumb-wrapper {
    cursor: pointer;
  }
  
  .thumb-wrapper img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border 0.3s;
  }
  
  .thumb-wrapper img:hover,
  .thumb-wrapper img:focus {
    border: 2px solid #007bff;
  }
  
  .image-container{height:500px;background-color: #191919;}
.image-container img{object-fit: contain}
.image-thumb-container{height:500px;gap: 5px;}
.thumb-wrapper{height:25%}
.thumb-wrapper img{object-fit:cover;height:100%;width:100%}
@media only screen and (max-width: 600px) {
	.image-container{height:350px;}
	.image-thumb-container{height:350px;gap: 5px;}
}